<template>
  <div class='add-nominee-main-wrapper'>
    <a-form
      ref='formRef'
      name='dynamic_form_nest_item'
      :model='dynamicValidateForm'
      @finish='onFinish'
    >
      <a-row style='display: unset'>
        <a-col>
          <div style='margin-top: 100px'>
            <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
            <div>
              <div class='add-nominee-title'>Let's add your nominee details</div>
              <div class='add-nominee-stepper'>Step 7</div>

              <div class='add-nominee-switch'>
                <span class='add-nominee-switch-text'>
                  Would you like to add nominee details?</span
                >
                <a-switch
                  size='small'
                  style='float: right; margin-top: 10px'
                  v-model:checked='nomineeRequired'
                  @change='onAddNominee'
                  checked-children="Yes" un-checked-children="No"
                  :disabled='disable'
                />
              </div>
              <div v-if='addNominee' class='add-nominee-top-text'>
                You can add upto 3 nominees
              </div>
              <div v-if='addNominee'>
                <a-space
                  v-for='(nominee, index) in dynamicValidateForm.nominees'
                  :key='nominee.id'
                  style='display: grid'
                >
                  <div style='display: flex; margin-top: 30px'>
                    <div style='width: 100%; margin-right: 10px'>
                      <div padding-bottom='10px'>
                      <div style="border-radius: 5px; background-color: #D1DBFD;
                      width: 20%; color: #0D44F5;">
                        <div>
                        Nominee {{ index + 1 }}
                        </div>
                      </div>
                    </div>
                      <div class='add-nominee-inputs-wrapper'>
                        <div style='width: 100%; padding-top: 10px;'>
                          <a-form-item
                            :name="['nominees', index, 'name']"
                            :rules='{
                              required: true,
                              message: "Please enter Name",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.name'
                              placeholder='Nominee Name'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'relation']"
                            :rules='{
                              required: true,
                              message: "Please select relation",
                            }'
                          >
                            <!-- <a-input
                              class='input-field'
                              v-model:value='nominee.relation'
                              placeholder='Relationship'
                            >
                            </a-input> -->
                            <a-select
                              class='select-field'
                              v-model:value='nominee.relation'
                              placeholder='Relationship'
                              :options='relationshipOptions'
                              style='text-align: initial'
                              :disabled='disable'
                            ></a-select>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'mobNo']"
                            :rules='{
                              required: true,
                              message: "Please enter Mobile No",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.mobNo'
                              placeholder='Nominee Mob No.'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'email']"
                            :rules='{
                              required: true,
                              message: "Please enter Email Address",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.email'
                              placeholder='Nominee Email Address'
                              type='text'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'addressLine1']"
                            :rules='{
                              required: true,
                              message: "Please enter Flat, Building",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.addressLine1'
                              placeholder='Flat, Building'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'addressLine2']"
                            :rules='{
                              required: true,
                              message: "Please enter Street, Area, Village",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.addressLine2'
                              placeholder='Street, Area, Village'
                              type='text'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'city']"
                            :rules='{
                              required: true,
                              message: "Please enter City",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.city'
                              placeholder='City'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'state']"
                            :rules='{
                              required: true,
                              message: "Please enter state",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.state'
                              placeholder='State'
                              type='text'
                              :disabled='disable'
                              :max= 100
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'country']"
                            :rules='{
                              required: true,
                              message: "Please enter Country",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.country'
                              placeholder='Country'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'pincode']"
                            :rules='{
                              required: true,
                              message: "Please enter Pincode",
                            }'
                          >
                            <a-input-number
                              class='input-field'
                              v-model:value='nominee.pincode'
                              placeholder='Pincode'
                              type='number'
                              :disabled='disable'
                            >
                            </a-input-number>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'dob']"
                            :rules='{
                              required: true,
                              message: "Please enter DOB",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.dob'
                              placeholder='Nominee DOB'
                              type='date'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'share']"
                            :rules='{
                              required: true,
                              message: "Please enter share",
                            }'
                          >
                            <a-input-number
                              class='input-field'
                              v-model:value='nominee.share'
                              placeholder='Nominee Share (%)'
                              type='number'
                              :disabled='disable'
                              :max= 100
                            >
                            </a-input-number>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style="width: 100%">
                          <div class='add-nominee-upload-btn'>
                            <a-upload
                            v-model:file-list='nominee.fileList'
                            accept='image/png, image/jpeg, image/jpg'
                            :max-Count='1'
                            method="GET"
                            @change="handleFileChange"
                            >
                            <a-button :disabled='disable'
                            style='width: 100%; height: 44px; border: 1px solid #79a89b'>
                            <span style='float: left'> Upload Nominee's Proof of Identity </span>
                            <cloud-upload-outlined style='font-size: 20px; float: right' />
                          </a-button>
                        </a-upload>
                      </div>
                    </div>
                  </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'password']"
                            :rules='{
                              required: false,
                            }'
                          >
                          <a-input-password
                      class='input-field'
                      v-model:value='nominee.password'
                      placeholder='Enter Password, if the file is protected with one'
                      :disabled='disable'
                    />
                          </a-form-item>
                        </div>
                      </div>
                      <div class='add-nominee-password-bottom-text'>
                  *Accepted Documents:PAN / Aadhar / Driver's License / Voter-ID / Birth Certificate
                </div>
                    </div>
                    <div style='margin-left: auto; padding-top: 12px'>
                      <MinusCircleOutlined @click='removeUser(nominee)'
                      :style="dynamicValidateForm.nominees.length == 1 ? 'display:none' : ''"/>
                    </div>
                  </div>
                </a-space>
                <div
                  :style="dynamicValidateForm.nominees.length > 2 ? 'display:none' : ''"
                  class='add-new-nominee-btn-wrapper'
                >
                  <a-button style='display: none;' type='primary'
                  :disabled='disabled' @click='addUser'
                    >+ Add New Nominee</a-button
                  >
                </div>
              </div>
              <div class='add-nominee-btn-wrapper'>
                <a-button type='primary' @click='onFinish' :loading="loading">Save</a-button>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
/* eslint-disable */
// import { useRouter } from 'vue-router';
import { MinusCircleOutlined } from '@ant-design/icons-vue';
import { onMounted, ref, reactive } from 'vue';
import { Form, message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

export default {
  components: {
    MinusCircleOutlined,
    CloudUploadOutlined
  },
  setup() {
    const store = useStore();
    const disable = ref(false);
    const loading = ref(false);
    const responseStage = ref('')
    const nomineeRequired = ref(false);
    const addNominee = ref(false);
    const dynamicValidateForm = reactive({
      nominees: [],
    });
    const relationshipOptions = ref([
      {
        value: 'FATHER',
        label: 'Father',
      },
      {
        value: 'MOTHER',
        label: 'Mother',
      },
      {
        value: 'SISTER',
        label: 'Sister',
      },
      {
        value: 'BROTHER',
        label: 'Brother',
      },
      {
        value: 'SPOUSE',
        label: 'Spouse',
      },
      {
        value: 'SON',
        label: 'Son',
      },
      {
        value: 'DAUGHTER',
        label: 'Daughter',
      },
    ]);
    const removeUser = (item) => {
      const index = dynamicValidateForm.nominees.indexOf(item);

      if (index !== -1) {
        dynamicValidateForm.nominees.splice(index, 1);
      }
    };
    const addUser = () => {
      dynamicValidateForm.nominees.push({
        name: '',
        relation: undefined,
        dob: '',
        mobNo:'',
        email:'',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        share: null,
        id: Date.now(),
        fileList: [],
        password: '',
      });
    };
    onMounted(() => {
      window.scrollTo(0, 0);
      addUser();
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        })
        .catch(() => {
        });
    });
    // const router = useRouter();
    const onAddNominee = () => {
      if (nomineeRequired.value === true) {
        addNominee.value = true;
      } else {
        addNominee.value = false;
      }
    };
    const formRef = ref();
    
    const handleFileChange = (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }

    const onFinish = async() => {
      if (!nomineeRequired.value) {
        try {

          loading.value = true;
          disable.value = true;
          const formData = new FormData();
          formData.append('nomineeRequired', nomineeRequired.value)
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          const response = await services.addNominee(formData, config)
          loading.value = false
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        }
        catch(e) {
          loading.value = false;
          disable.value = false;
          message.error(e.response.data.error.name, 2);
        };
      } else {
        const formData = new FormData();
        const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
        const data = {
          nomineeRequired: nomineeRequired.value,
          nominees: dynamicValidateForm.nominees,
        };
        const shareTotal = data.nominees.reduce((initialValue, itr) => initialValue += itr.share, 0);
        if (shareTotal !== 100) {;
          const message = 'Validation error';
          const description = 'Total distribution of shares among nominee should be 100%';
          Operations.notificationError(message, description);
        } else {
          try {

            loading.value = true;
            disable.value = true;
            for(const nominee of dynamicValidateForm.nominees) {
              formData.append('documentType', "NOMINEE")
          formData.append('nomineeRequired', nomineeRequired.value)
          formData.append('name', nominee.name)
          formData.append('email', nominee.email)
          formData.append('mobile', nominee.mobNo)
          formData.append('address1', nominee.addressLine1)
          formData.append('address2', nominee.addressLine2)
          formData.append('dob', nominee.dob)
          formData.append('relation', nominee.relation)
          formData.append('share', nominee.share)
          formData.append('city', nominee.city)
          formData.append('state', nominee.state)
          formData.append('country', nominee.country)
          formData.append('pin', nominee.pincode)
          formData.append('file', nominee.fileList[0].originFileObj)
          formData.append('password', nominee.password)
          const response = await services.addNominee(formData, config)
          loading.value = false;
          responseStage.value = response.data.data.stage
        }
      }
        catch(e) {
            loading.value = false;
              disable.value = false;
              message.error(e.response.data.error.name, 2);
            };
        store.dispatch('onboardingStore/updateStage', responseStage);
        } 
      }
    };


    return {
      nomineeRequired,
      onAddNominee,
      addNominee,
      formRef,
      dynamicValidateForm,
      onFinish,
      removeUser,
      addUser,
      relationshipOptions,
      handleFileChange,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/AddNominee.scss';
</style>
